import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getSource,
  getSources,
  createSource,
  updateSource,
  GetSourceRequestOptions,
  CreateSourceRequestOptions,
  UpdateSourceRequestOptions,
  DeleteSourceRequestOptions,
  deleteSource,
  SourceResponse
} from '@/services/api';
import { UseSourcesOptions } from './interfaces';

export function useSource(options: GetSourceRequestOptions, useOptions: Omit<UseQueryOptions<SourceResponse, unknown, SourceResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('tag', () => getSource(options), useOptions);
}

export function useSources({ page, keyword, sortBy, descSortBy }: UseSourcesOptions = {}) {
  return useQuery(
    reactive(['tags', { page, keyword, sortBy, descSortBy }]),
    () => getSources({
      query: {
        page: unref(page),
        keyword: unref(keyword),
        sortBy: unref(sortBy),
        descSortBy: unref(descSortBy)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateSource() {
  return useMutation((options: CreateSourceRequestOptions) => createSource(options));
}

export function useUpdateSource() {
  return useMutation((options: UpdateSourceRequestOptions) => updateSource(options));
}

export function useDeleteSource() {
  return useMutation((options: DeleteSourceRequestOptions) => deleteSource(options));
}
