import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getSeo,
  getSeos,
  createSeo,
  updateSeo,
  deleteSeo,
  GetSeoRequestOptions,
  CreateSeoRequestOptions,
  UpdateSeoRequestOptions,
  DeleteSeoRequestOptions,
  SeoResponse
} from '@/services/api';
import { UseSeosOptions } from './interfaces';

export function useSeo(options: GetSeoRequestOptions, useOptions: Omit<UseQueryOptions<SeoResponse, unknown, SeoResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('tag', () => getSeo(options), useOptions);
}

export function useSeos({ page, keyword, sortBy, descSortBy }: UseSeosOptions = {}) {
  return useQuery(
    reactive(['tags', { page, keyword, sortBy, descSortBy }]),
    () => getSeos({
      query: {
        page: unref(page),
        keyword: unref(keyword),
        sortBy: unref(sortBy),
        descSortBy: unref(descSortBy)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateSeo() {
  return useMutation((options: CreateSeoRequestOptions) => createSeo(options));
}

export function useUpdateSeo() {
  return useMutation((options: UpdateSeoRequestOptions) => updateSeo(options));
}

export function useDeleteSeo() {
  return useMutation((options: DeleteSeoRequestOptions) => deleteSeo(options));
}
