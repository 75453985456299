import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getBlock,
  getBlocks,
  createBlock,
  updateBlock,
  GetBlockRequestOptions,
  CreateBlockRequestOptions,
  UpdateBlockRequestOptions,
  DeleteBlockRequestOptions,
  deleteBlock,
  UpdateBlockVideosRequestOptions,
  updateBlockVideos,
  // UpdateBlockModelsRequestOptions,
  // updateBlockModels,
  BlockResponse
} from '@/services/api';
import { UseBlocksOptions } from './interfaces';

export function useBlock(options: GetBlockRequestOptions, useOptions: Omit<UseQueryOptions<BlockResponse, unknown, BlockResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('block', () => getBlock(options), useOptions);
}

export function useBlocks({ page, keyword, type, sortBy, descSortBy }: UseBlocksOptions = {}) {
  return useQuery(
    reactive(['blocks', { page, keyword, type, sortBy, descSortBy }]),
    () => getBlocks({
      query: {
        page: unref(page),
        keyword: unref(keyword),
        type: unref(type),
        sortBy: unref(sortBy),
        descSortBy: unref(descSortBy)
      }
    }),
    { keepPreviousData: true }
  );
}

export function useCreateBlock() {
  return useMutation((options: CreateBlockRequestOptions) => createBlock(options));
}

export function useUpdateBlock() {
  return useMutation((options: UpdateBlockRequestOptions) => updateBlock(options));
}

export function useDeleteBlock() {
  return useMutation((options: DeleteBlockRequestOptions) => deleteBlock(options));
}

export function useSaveBlockVideos() {
  return useMutation((options: UpdateBlockVideosRequestOptions) => updateBlockVideos(options));
}

// export function useSaveBlockModels() {
//   return useMutation((options: UpdateBlockModelsRequestOptions) => updateBlockModels(options));
// }
